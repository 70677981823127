import { GatsbyLink, GatsbyLinkProps } from "@mgdx-libs/link";
import clsx from "clsx";
import React from "react";

import { Size, Variant, WidthType } from "./FilledButton";
import * as styles from "./FilledButton.module.css";

export type FilledLinkButtonProps = Omit<GatsbyLinkProps<EmptyObject>, "ref"> & {
  variant: `${Variant}`;
  size?: `${Size}`;
  widthType?: `${WidthType}`;
  multiline?: boolean;
  disabled?: boolean;
  loading?: boolean;
};

export const FilledLinkButton: React.FC<FilledLinkButtonProps> = ({
  variant,
  size = Size.M,
  widthType = WidthType.FitContent,
  multiline,
  disabled,
  loading,
  children,
  className,
  to,
  ...props
}) => (
  <div
    className={clsx(
      styles.base,
      variant === Variant.Primary && styles.primary,
      variant === Variant.PrimaryWeak && styles.primaryWeak,
      variant === Variant.Secondary && styles.secondary,
      variant === Variant.Caution && styles.caution,
      widthType === WidthType.FitContent && styles.fitContent,
      widthType === WidthType.FullWidth && styles.fullWidth,
      size === Size.S && styles.sizeS,
      size === Size.M && styles.sizeM,
      size === Size.L && styles.sizeL,
      multiline && styles.multiline,
      disabled && styles.disabled,
      loading && styles.loading,
      className
    )}
  >
    <GatsbyLink
      to={to}
      className={styles.button}
      aria-disabled={disabled}
      tabIndex={disabled ? -1 : undefined}
      {...props}
    >
      {children}
    </GatsbyLink>
  </div>
);
