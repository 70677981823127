import { getAccessToken } from "@mgdx-libs/firebase";

import { createFetchTimeout } from "./createFetchTimeout";
import { InterviewApi } from "./pharmacy/counselor";
import requestHeaders from "./requestHeaders";
import { Configuration } from "./runtime";

export const pharmacyInterViewApi = new InterviewApi(
  new Configuration({
    basePath: process.env.MGDX_API_COUNSELOR_URL,
    credentials: "include",
    accessToken: getAccessToken,
    headers: requestHeaders,
    fetchApi: createFetchTimeout(60000, "pharmacyInterViewApi"),
  })
);
