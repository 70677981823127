import { getAccessToken } from "@mgdx-libs/firebase";

import { ClinicApi, Configuration } from "./clinic/clinic";
import { createFetchTimeout } from "./createFetchTimeout";
import requestHeaders from "./requestHeaders";

export const clinicApi = new ClinicApi(
  new Configuration({
    basePath: process.env.MGDX_API_CLINIC_URL,
    credentials: "include",
    accessToken: getAccessToken,
    headers: requestHeaders,
    fetchApi: createFetchTimeout(60000, "clinicApi"),
  })
);
