import { getAccessToken } from "@mgdx-libs/firebase";

import { CounselingApi } from "./clinic/counselor";
import { createFetchTimeout } from "./createFetchTimeout";
import requestHeaders from "./requestHeaders";
import { Configuration } from "./runtime";

export const clinicCounselingApi = new CounselingApi(
  new Configuration({
    basePath: process.env.MGDX_API_CLINIC_COUNSELOR_URL,
    credentials: "include",
    accessToken: getAccessToken,
    headers: requestHeaders,
    fetchApi: createFetchTimeout(60000, "clinicCounselingApi"),
  })
);
