import { Moment } from "@mgdx-libs/moment";

import parseDate from "../utils/parseDate";

const DATE_TO_SHORT_TEXT_FORMAT = "M月D日(ddd)" as const;
const DATE_TO_LOCALE_SHORT_TEXT_FORMAT = "M月D日(ddd曜)" as const;
const DATE_TO_TEXT_FORMAT = "M月D日(ddd) H:mm" as const;
const DATE_TO_LONG_TEXT_FORMAT = "YYYY年M月D日(ddd) H:mm" as const;
const DATE_TO_LOCALE_ACCESSIBILITY_FORMAT = "M月D日(ddd曜) H:mm" as const;
const DATE_TO_LOCALE_ACCESSIBILITY_LONG_TEXT_FORMAT = "YYYY年M月D日(ddd曜) H:mm" as const;

const DATE_TO_SHORT_TEXT_FORMAT_MEDICAL = "M/D(ddd)" as const;
const DATE_TO_TEXT_FORMAT_MEDICAL = "M/D(ddd) H:mm" as const;
const DATE_TO_LONG_TEXT_FORMAT_MEDICAL = "YYYY/M/D(ddd) H:mm" as const;

const DATE_TO_FORM = "YYYY-MM-DD" as const;
const DATE_TO_LONG_FORM = "YYYY-MM-DD HH:mm" as const;
const DATE_TO_LOCALE_FORM = "YYYY年M月D日(ddd)" as const;
const DATE_TO_LOCALE = "YYYY年M月D日(ddd曜)" as const;

const DATE_TO_TIME = "H:mm" as const;

export type DateToTextOptions = {
  withTilde?: boolean;
  medical?: boolean;
  isForAccessibility?: boolean;
};

export type DateToText = (dateText?: string | Moment | null | undefined, options?: DateToTextOptions) => string;

export const dateToShortText: DateToText = (dateText, options) => {
  const date = parseDate(dateText);

  if (date) {
    if (options?.withTilde) {
      return date.format(
        options?.medical ? `${DATE_TO_SHORT_TEXT_FORMAT_MEDICAL} 〜` : `${DATE_TO_SHORT_TEXT_FORMAT} 〜`
      );
    }

    return date.format(options?.medical ? DATE_TO_SHORT_TEXT_FORMAT_MEDICAL : DATE_TO_SHORT_TEXT_FORMAT);
  }

  return "";
};

export const dateToText: DateToText = (dateText, options) => {
  const date = parseDate(dateText);

  if (date) {
    if (options?.withTilde) {
      return date.format(options?.medical ? `${DATE_TO_TEXT_FORMAT_MEDICAL} 〜` : `${DATE_TO_TEXT_FORMAT} 〜`);
    }

    if (options?.isForAccessibility) {
      return date.format(DATE_TO_LOCALE_ACCESSIBILITY_FORMAT);
    }

    return date.format(options?.medical ? DATE_TO_TEXT_FORMAT_MEDICAL : DATE_TO_TEXT_FORMAT);
  }

  return "";
};

export const dateToLongText: DateToText = (dateText, options) => {
  const date = parseDate(dateText);

  if (date) {
    if (options?.withTilde) {
      return date.format(
        options?.medical ? `${DATE_TO_LONG_TEXT_FORMAT_MEDICAL} 〜` : `${DATE_TO_LONG_TEXT_FORMAT} 〜`
      );
    }

    if (options?.isForAccessibility) {
      return date.format(DATE_TO_LOCALE_ACCESSIBILITY_LONG_TEXT_FORMAT);
    }

    return date.format(options?.medical ? DATE_TO_LONG_TEXT_FORMAT_MEDICAL : DATE_TO_LONG_TEXT_FORMAT);
  }

  return "";
};

export const dateToFormText = (dateText?: Moment | string | null): string => {
  if (!dateText) return "";

  if (typeof dateText === "string") {
    const date = parseDate(dateText);
    return date?.format(DATE_TO_FORM) || "";
  } else {
    const date = dateText as Moment;
    return date?.format(DATE_TO_FORM) || "";
  }
};

export const dateToLocaleFormText = (dateText?: Moment | string | null): string => {
  if (!dateText) return "";

  if (typeof dateText === "string") {
    const date = parseDate(dateText);
    return date?.format(DATE_TO_LOCALE_FORM) || "";
  } else {
    const date = dateText as Moment;
    return date?.format(DATE_TO_LOCALE_FORM) || "";
  }
};

export const dateToLocaleText = (dateText?: Moment | string | null): string => {
  if (!dateText) return "";

  if (typeof dateText === "string") {
    const date = parseDate(dateText);
    return date?.format(DATE_TO_LOCALE) || "";
  } else {
    const date = dateText as Moment;
    return date?.format(DATE_TO_LOCALE) || "";
  }
};

export const dateToLocaleShortText = (dateText?: Moment | string | null): string => {
  if (!dateText) return "";

  if (typeof dateText === "string") {
    const date = parseDate(dateText);
    return date?.format(DATE_TO_LOCALE_SHORT_TEXT_FORMAT) || "";
  } else {
    const date = dateText as Moment;
    return date?.format(DATE_TO_LOCALE_SHORT_TEXT_FORMAT) || "";
  }
};

export const dateToLongFormText = (dateText?: Moment | string | null): string => {
  if (!dateText) return "";

  if (typeof dateText === "string") {
    const date = parseDate(dateText);
    return date?.format(DATE_TO_LONG_FORM) || "";
  } else {
    const date = dateText as Moment;
    return date?.format(DATE_TO_LONG_FORM) || "";
  }
};

export const dateToTimeText: DateToText = (dateText) => {
  const date = parseDate(dateText);

  if (date) {
    return date.format(DATE_TO_TIME);
  }

  return "";
};

export const dateToJapaneseCalendarFormText = (dateText?: string | Moment | null | undefined) => {
  if (!dateText) return "";

  const date = parseDate(dateText);

  if (date) {
    const [year, month, day] = date
      .toDate()
      .toLocaleDateString("ja-JP-u-ca-japanese", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      })
      .split("/");

    if (year.length === 2) {
      const padYear = year.substring(0, 1) + year.substring(1).padStart(2, "0");
      return `${padYear}${month}${day}`;
    }

    return `${year}${month}${day}`;
  }

  return "";
};

export default dateToText;
