import { followUpApi } from "@mgdx/api/lib/followUpApi";
import {
  PutFollowUpAnswerRequestBody,
  PutFollowUpAnswerResponse,
  PutFollowUpAnswersRequest,
} from "@mgdx/api/lib/pharmacy/counselor";
import { createRequestAction } from "@mgdx-libs/redux-requests";
import toString from "lodash/toString";

export type RegisterMedicationParams = PutFollowUpAnswerRequestBody & Pick<PutFollowUpAnswersRequest, "counselingId">;

export const updateFollowUp = createRequestAction<RegisterMedicationParams, PutFollowUpAnswerResponse>(
  "requests/followup/updateFollowUp",
  ({ counselingId, answers, requestToken }, meta) => ({
    request: {
      promise: followUpApi.putFollowUpAnswers({
        counselingId,
        putFollowUpAnswerRequestBody: {
          answers,
          requestToken,
        },
      }),
    },
    meta: {
      requestKey: toString(counselingId),
      ...meta,
    },
  })
);
