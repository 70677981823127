import { creditCardApi } from "@mgdx/api/lib/creditCardApi";
import { PayerCreditCard } from "@mgdx/api/lib/platform/payer";
import { toastInfo } from "@mgdx/ui/components/Toast";
import { createRequestAction } from "@mgdx-libs/redux-requests";

export const findCreditCard = createRequestAction<void, PayerCreditCard[]>(
  "requests/creditCard/findCreditCard",
  (_, meta) => ({
    request: {
      promise: creditCardApi.getCreditCards(),
    },
    meta,
  })
);

export type RegisterCreditCardParams = { defaultCard: boolean; token: string };

export const registerCreditCard = createRequestAction<RegisterCreditCardParams, PayerCreditCard>(
  "requests/creditCard/registerCreditCard",
  ({ defaultCard, token }, meta) => ({
    request: {
      promise: creditCardApi.postCreditCards({
        payerCreditCardRegisterRequest: {
          defaultCard: defaultCard,
          token: token,
        },
      }),
    },
    meta,
  })
);

export type EditCreditCardParam = { creditCardId: string; defaultCard: boolean; token: string };

export const editCreditCard = createRequestAction<EditCreditCardParam, PayerCreditCard>(
  "requests/creditCard/editCreditCard",
  ({ creditCardId, defaultCard, token }, meta) => ({
    request: {
      promise: creditCardApi.putCreditCard({
        creditCardId: creditCardId,
        payerCreditCardRegisterRequest: {
          defaultCard: defaultCard,
          token: token,
        },
      }),
    },
    meta,
  })
);

export type DeleteCreditCardParam = { creditCardId: string };

export const deleteCreditCard = createRequestAction<DeleteCreditCardParam, void, boolean>(
  "requests/creditCard/deleteCreditCard",
  ({ creditCardId }, meta) => ({
    request: {
      promise: creditCardApi.deleteCreditCard({
        creditCardId: creditCardId,
      }),
    },
    meta: {
      onSuccess: (response, action, store) => {
        toastInfo("クレジットカードを削除しました。");
        return { ...response, data: true };
      },
      ...meta,
    },
  })
);
