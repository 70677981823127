import { familyAccountApi } from "@mgdx/api/lib/familyAccountApi";
import { patientApi } from "@mgdx/api/lib/patientApi";
import {
  FamilyAccount,
  FamilyAccountCreateAttributes,
  FamilyAccountsUpsertAttributes,
  FamilyAccountUpdateAttributes,
  Patient,
} from "@mgdx/shared/src/models/Patient";
import { toastInfo } from "@mgdx/ui/components/Toast";
import { createRequestAction } from "@mgdx-libs/redux-requests";

import { optimisticallyAddFamilyAccount } from "../currentUser";
import { findCurrentUser } from "./patient";

export const findFamilyAccount = createRequestAction<void, Patient, FamilyAccount[]>(
  "requests/patient/findFamilyAccount",
  (_, meta) => ({
    request: {
      promise: patientApi.getCurrentPatient(),
    },
    meta: {
      ...meta,
      onSuccess: (response, action, store) => {
        const patient = response.data as Partial<Patient>;
        return { ...response, data: patient?.familyAccount ?? [] };
      },
    },
  })
);

export type RegisterFamilyAccountParams = FamilyAccountCreateAttributes;

export const registerFamilyAccount = createRequestAction<RegisterFamilyAccountParams, FamilyAccount>(
  "requests/patient/registerFamilyAccount",
  (postFamilyAccountRequestBody, meta) => ({
    request: {
      promise: familyAccountApi.createFamilyAccount({
        postFamilyAccountRequestBody,
      }),
    },
    meta: {
      onSuccess: (response, action, store) => {
        toastInfo("家族情報を追加しました。");
        store.dispatch(optimisticallyAddFamilyAccount(response.data));
        store.dispatch(findCurrentUser());
        return response;
      },
      ...meta,
    },
  })
);

export type UpdateFamilyAccountParam = { familyAccountId: number } & FamilyAccountUpdateAttributes;

export const updateFamilyAccount = createRequestAction<UpdateFamilyAccountParam, FamilyAccount>(
  "requests/patient/updateFamilyAccount",
  ({ familyAccountId, ...patchFamilyAccountRequestBody }, meta) => ({
    request: {
      promise: familyAccountApi.updateFamilyAccount({
        familyAccountId,
        patchFamilyAccountRequestBody: {
          ...patchFamilyAccountRequestBody,
        },
      }),
    },
    meta,
  })
);

export type UpsertFamilyAccountParam = FamilyAccountsUpsertAttributes;

export const upsertFamilyAccount = createRequestAction<UpsertFamilyAccountParam, FamilyAccount[]>(
  "requests/patient/upsertFamilyAccount",
  (postFamilyAccountRequestBody, meta) => ({
    request: {
      promise: familyAccountApi.upsertFamilyAccount({
        postFamilyAccountsUpsertRequestBody: postFamilyAccountRequestBody,
      }),
    },
    meta,
  })
);
