import { linkedPatientApi } from "@mgdx/api/lib/linkedPatientApi";
import { createRequestAction } from "@mgdx-libs/redux-requests";

export const deleteLinkedPatient = createRequestAction<void, void, boolean>(
  "requests/user/deleteLinkedPatient",
  (_, meta) => ({
    request: {
      promise: linkedPatientApi.deleteLinkedPatient(),
    },
    meta: {
      onSuccess: (response, requestAction, store) => {
        return { ...response, data: true };
      },
      ...meta,
    },
  })
);
