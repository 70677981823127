import { Liff } from "@liff/liff-types";
import { linkedPatientApi } from "@mgdx/api/lib/linkedPatientApi";
import { patientApi } from "@mgdx/api/lib/patientApi";
import { toastError } from "@mgdx/ui/components/Toast";
import {
  checkErrorResponse,
  errorHandlerReport,
  firebaseErrorHandler,
  isFirebaseError,
} from "@mgdx-libs/error-handler";
import { getFirebaseAuth, getFirebaseUser, setAccessToken } from "@mgdx-libs/firebase";
import { navigate } from "@mgdx-libs/link";
import { logger } from "@mgdx-libs/logger";
import { useLocation } from "@mgdx-libs/router";
import { signInWithCustomToken } from "firebase/auth";
import httpStatus from "http-status";
import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { setCurrentUserWithDispatch, setInLineApp, setInProcessOfSignup, setLiffLogined } from "../ducks/currentUser";
import { clearLiffParams, LiffState, LinkContent, setLiffParams } from "../ducks/liff";
import { MEDICINE_NOTEBOOK_DEFAULT_ACCOUNT_ID } from "../providers/MedicineNotebookAccountProvider";
import firebaseErrorMessages, { defaultErrorMessage } from "../translations/firebaseErrorMessages";
import { setInAppView } from "./useIsInAppView";
import useLiffParams from "./useLiffParams";
import useLoadLiff from "./useLoadLiff";
import { LocationState } from "./useLocationState";

export const parseRedirectUrl = ({
  pharmacyId,
  pharmacyCounselingId,
  familyPharmacistId,
  linkContent,
  campaignId,
  medicineNotebookShareCode,
  phrAccountId,
  familyAccountId,
  dispenseRecordId,
  otcMedicineId,
}: LiffState): string => {
  switch (linkContent) {
    case "counselings":
      return "/counselings/";
    case "creditcard":
      return "/settings/creditcard/";
    case "family-account":
      return "/settings/family-account/";
    case "medicine-notebook":
      if (familyAccountId && familyAccountId !== "0")
        return `/medicine-notebook/family-${familyAccountId}/${pharmacyId ? `?pharmacy_id=${pharmacyId}` : ""}`;
      return `/medicine-notebook/${MEDICINE_NOTEBOOK_DEFAULT_ACCOUNT_ID}/${
        pharmacyId ? `?pharmacy_id=${pharmacyId}` : ""
      }`;
    case "profile":
      return "/settings/profile/";
    case "inquiry":
      return "/inquiry/";
    case "rules":
      return "/rules/";
    case "campaign":
      if (campaignId) return `/campaign/?campaign_id=${campaignId}`;
      break;
    case "mission-campaign":
      return "/campaign/mission-campaign/";
    case "medicine-notebook-auto-link-pharmacies":
      if (pharmacyId) {
        return `/medicine-notebook/${MEDICINE_NOTEBOOK_DEFAULT_ACCOUNT_ID}/auto-link-pharmacies/${pharmacyId}/new/`;
      } else {
        return `/medicine-notebook/${MEDICINE_NOTEBOOK_DEFAULT_ACCOUNT_ID}/auto-link-pharmacies/`;
      }
    case "family-pharmacist":
      if (familyPharmacistId) return `/family-pharmacists/${familyPharmacistId}/`;
      break;
    case "medicine-notebook-share":
      if (medicineNotebookShareCode)
        return `/medicine-notebook/share/issues/search/?code=${encodeURIComponent(medicineNotebookShareCode)}`;
      break;
    case "phr-myna-linkage-processing":
      if (phrAccountId) return `/phr/${phrAccountId}/myna-linkage-processing/` + window.location.search;
      break;
    case "phr-myna-linkage-canceled":
      if (phrAccountId) return `/phr/${phrAccountId}/myna-linkage-canceled/` + window.location.search;
      break;
    case "phr":
      return "/phr/";
    case "medicine-notebook-check-prescription-dispensed":
      if (familyAccountId && dispenseRecordId) {
        return `/medicine-notebook/family-${familyAccountId}/records/${dispenseRecordId}/prescription/check/`;
      } else if (dispenseRecordId) {
        return `/medicine-notebook/${MEDICINE_NOTEBOOK_DEFAULT_ACCOUNT_ID}/records/${dispenseRecordId}/prescription/check/`;
      }
      break;
    case "medicine-notebook-check-otc-dispensed":
      if (familyAccountId && otcMedicineId) {
        return `/medicine-notebook/family-${familyAccountId}/records/${otcMedicineId}/otc/check/`;
      } else if (otcMedicineId) {
        return `/medicine-notebook/${MEDICINE_NOTEBOOK_DEFAULT_ACCOUNT_ID}/records/${otcMedicineId}/otc/check/`;
      }
      break;
  }

  if (pharmacyId !== undefined) {
    switch (linkContent) {
      case "counseling":
        return `/pharmacies/${pharmacyId}/reserve-counseling/`;
      case "dispensing":
        return `/pharmacies/${pharmacyId}/reserve-dispensing/`;
    }
    return `/pharmacies/${pharmacyId}/`;
  } else if (pharmacyCounselingId !== undefined) {
    switch (linkContent) {
      case "followups":
        return `/pharmacy-followups/${pharmacyCounselingId}/`;
      case "pick-up":
        return `/pharmacy-counselings/${pharmacyCounselingId}/pick-up`;
      case "payments":
        return `/pharmacy-payments/${pharmacyCounselingId}/detail`;
      case "direct-visit-reception":
        return `/pharmacy-counselings/${pharmacyCounselingId}/accept`;
      default:
        return `/pharmacy-counselings/${pharmacyCounselingId}/`;
    }
  }

  return "/";
};

export const createCustomTokenUser = async (customToken: string) => {
  const firebaseAuth = getFirebaseAuth();
  const user = await signInWithCustomToken(firebaseAuth, customToken).catch((error) => {
    const message = firebaseErrorMessages("getRedirectResult", error);
    if (message) {
      // Expected firebase errors
      toastError(`ログインに失敗しました。${message}`);
      errorHandlerReport(message);
    } else {
      // Other firebase errors
      if (isFirebaseError(error)) {
        try {
          firebaseErrorHandler(error);
        } catch (e) {
          errorHandlerReport(e as Error);
        }
      } else {
        // Other errors
        errorHandlerReport(error);
      }
      toastError(`ログインに失敗しました。${defaultErrorMessage}`);
    }
    return undefined;
  });

  const firebaseUser = user?.user;
  if (!firebaseUser) return undefined;

  logger.debug("firebase.User: %o", firebaseUser);
  const accessToken = await firebaseUser.getIdToken();
  logger.debug("getIdToken: %s", accessToken);
  setAccessToken(accessToken);

  return firebaseUser;
};

type UseInitializeLiff = (args: {
  pharmacyId?: string;
  pharmacyCounselingId?: string;
  familyPharmacistId?: string;
  linkContent?: LinkContent;
  skipAccountSelect?: boolean;
  requestToken?: string;
  campaignId?: string;
  invitationId?: string;
  medicineNotebookShareCode?: string;
  phrAccountId?: string;
  familyAccountId?: string;
  dispenseRecordId?: string;
  otcMedicineId?: string;
}) => string;

const useInitializeLiff: UseInitializeLiff = ({
  pharmacyId,
  pharmacyCounselingId,
  familyPharmacistId,
  linkContent,
  skipAccountSelect,
  requestToken,
  campaignId,
  invitationId,
  medicineNotebookShareCode,
  phrAccountId,
  familyAccountId,
  dispenseRecordId,
  otcMedicineId,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const liffParams = useLiffParams();
  const selectPharmacyId = useMemo(() => pharmacyId ?? liffParams.pharmacyId, [liffParams.pharmacyId, pharmacyId]);
  const selectCounselingId = useMemo(
    () => pharmacyCounselingId ?? liffParams.pharmacyCounselingId,
    [liffParams.pharmacyCounselingId, pharmacyCounselingId]
  );
  const selectFamilyPharmacistId = useMemo(
    () => familyPharmacistId ?? liffParams.familyPharmacistId,
    [liffParams.familyPharmacistId, familyPharmacistId]
  );
  const selectPhrAccountId = useMemo(
    () => phrAccountId ?? liffParams.phrAccountId,
    [liffParams.phrAccountId, phrAccountId]
  );
  const selectLinkContent = useMemo(() => linkContent ?? liffParams.linkContent, [liffParams.linkContent, linkContent]);
  const selectSkipAccountSelect = useMemo(
    () => skipAccountSelect ?? liffParams.skipAccountSelect,
    [liffParams.skipAccountSelect, skipAccountSelect]
  );
  const selectCampaignId = useMemo(() => campaignId ?? liffParams.campaignId, [campaignId, liffParams.campaignId]);
  const selectInvitationId = useMemo(
    () => invitationId ?? liffParams.invitationId,
    [invitationId, liffParams.invitationId]
  );
  const selectMedicineNotebookShareCode = useMemo(
    () => medicineNotebookShareCode ?? liffParams.medicineNotebookShareCode,
    [medicineNotebookShareCode, liffParams.medicineNotebookShareCode]
  );
  const selectFamilyAccountId = useMemo(
    () => familyAccountId ?? liffParams.familyAccountId,
    [liffParams.familyAccountId, familyAccountId]
  );
  const selectDispenseRecordId = useMemo(
    () => dispenseRecordId ?? liffParams.dispenseRecordId,
    [liffParams.dispenseRecordId, dispenseRecordId]
  );
  const selectOtcRecordId = useMemo(
    () => otcMedicineId ?? liffParams.otcMedicineId,
    [liffParams.otcMedicineId, otcMedicineId]
  );

  const [title, setTitle] = useState<string>("認証中");

  const initialize = useCallback(
    async (liffId: string, liff: Liff) => {
      await dispatch(setInProcessOfSignup(true));

      await liff.init({ liffId, withLoginOnExternalBrowser: true }).catch((error) => {
        errorHandlerReport(error);
      });
      if (liff.isInClient()) {
        dispatch(setInLineApp(true));
        setInAppView(true);
      }
      setTitle("認証中.");
      if (liff.isLoggedIn()) {
        dispatch(
          setLiffParams({
            pharmacyId: selectPharmacyId,
            pharmacyCounselingId: selectCounselingId,
            familyPharmacistId: selectFamilyPharmacistId,
            linkContent: selectLinkContent,
            skipAccountSelect: selectSkipAccountSelect,
            campaignId: selectCampaignId,
            invitationId: selectInvitationId,
            medicineNotebookShareCode: selectMedicineNotebookShareCode,
            phrAccountId: selectPhrAccountId,
            familyAccountId: selectFamilyAccountId,
            dispenseRecordId: selectDispenseRecordId,
            otcMedicineId: selectOtcRecordId,
          })
        );

        const firebaseUser = getFirebaseUser();
        if (!firebaseUser) {
          const idToken = await liff.getIDToken();
          if (idToken === null) {
            toastError(`ログインに失敗しました。${defaultErrorMessage}`);
            return;
          }
          const response = await linkedPatientApi
            .postLinkedPatientAuthentications({
              postLinkPatientAuthenticationRequestBody: { linkUserToken: idToken },
            })
            .catch(async (errorOrResponse) => {
              await checkErrorResponse(errorOrResponse);
              return undefined;
            });

          const customToken = response?.customToken;
          if (customToken === undefined) {
            toastError("ログインに失敗しました。LINEミニアプリを再度開き直してください。");
            errorHandlerReport("トークンが取得できません。");
            return;
          }

          setTitle("認証中..");
          const firebaseUser = await createCustomTokenUser(customToken);
          if (firebaseUser === undefined) {
            toastError("ログインに失敗しました。ユーザーが作成できませんでした。");
            errorHandlerReport("ユーザーが作成できませんでした。");
            return;
          }
        } else {
          const accessToken = await firebaseUser.getIdToken(true);
          logger.debug("getIdToken: %s", accessToken);
          setAccessToken(accessToken);
        }
        const redirectUrl = parseRedirectUrl({
          pharmacyId: selectPharmacyId,
          pharmacyCounselingId: selectCounselingId,
          familyPharmacistId: selectFamilyPharmacistId,
          linkContent: selectLinkContent,
          skipAccountSelect: selectSkipAccountSelect,
          campaignId: selectCampaignId,
          invitationId: selectInvitationId,
          medicineNotebookShareCode: selectMedicineNotebookShareCode,
          phrAccountId: selectPhrAccountId,
          familyAccountId: selectFamilyAccountId,
          dispenseRecordId: selectDispenseRecordId,
          otcMedicineId: selectOtcRecordId,
        });

        setTitle("認証中...");
        const user = await patientApi.getCurrentPatient().catch(async (errorOrResponse) => {
          if (errorOrResponse instanceof Response && errorOrResponse.status === httpStatus.NOT_FOUND) {
            const state: LocationState = { content: "register-liff", redirectUrl, requestToken };
            dispatch(clearLiffParams());
            if (selectSkipAccountSelect) {
              await navigate(`/liff/link-only/${location.search}`, { state, replace: true });
            } else {
              await navigate(`/liff/select/${location.search}`, { state, replace: true });
            }
            return undefined;
          }

          if (errorOrResponse instanceof Response || errorOrResponse instanceof DOMException) {
            const message = await checkErrorResponse(errorOrResponse);
            toastError(`ログインに失敗しました。${message}`);
          } else {
            toastError("ログインに失敗しました。LINEミニアプリを再度開き直してください。");
          }
          errorHandlerReport("患者情報を取得できません。");
          return undefined;
        });
        if (user === undefined) {
          return;
        }

        window.dataLayer.push({
          event: "complete_sign_in",
          user_id: user.id,
          provider: "line",
          liffId,
        });

        setTitle("薬急便起動中");
        let headers: HeadersInit | undefined;
        const token = liff.getAccessToken();
        if (token) {
          headers = { "X-LINE-Authorization": token };
        }
        await linkedPatientApi
          .putLinkedPatientNotificationAuthorization(
            { putLinkedPatientNotificationAuthorizationRequestBody: {} },
            { headers }
          )
          .catch(async (errorOrResponse) => {
            await checkErrorResponse(errorOrResponse);
          });

        dispatch(setInProcessOfSignup(false));
        dispatch(setLiffLogined(true));
        setCurrentUserWithDispatch(dispatch, user);
        dispatch(clearLiffParams());
        await navigate(redirectUrl, { replace: true, state: { requestToken } });
      }
    },
    [
      dispatch,
      location.search,
      requestToken,
      selectCampaignId,
      selectCounselingId,
      selectDispenseRecordId,
      selectFamilyAccountId,
      selectFamilyPharmacistId,
      selectInvitationId,
      selectLinkContent,
      selectMedicineNotebookShareCode,
      selectOtcRecordId,
      selectPharmacyId,
      selectPhrAccountId,
      selectSkipAccountSelect,
    ]
  );

  useLoadLiff(initialize);

  return title;
};

export default useInitializeLiff;
