import { PatchCounselingRequestBodyStatusEnum } from "@mgdx/api/lib/clinic/counselor";
import { clinicCounselingApi } from "@mgdx/api/lib/clinicCounselingApi";
import {
  ClinicCounseling,
  ClinicCounselingConnection,
  ClinicCounselingSearchParams,
} from "@mgdx/shared/src/models/ClinicCounseling";
import { toastInfo } from "@mgdx/ui/components/Toast";
import { createRequestAction } from "@mgdx-libs/redux-requests";
import toInteger from "lodash/toInteger";

export const findClinicCounselingById = createRequestAction<
  { counselingId: string; refresh?: boolean },
  ClinicCounseling
>("requests/clinicCounseling/findClinicCounselingById", ({ counselingId, refresh }, meta) => ({
  request: {
    promise: clinicCounselingApi.getCounseling({
      counselingId: toInteger(counselingId),
    }),
  },
  meta: {
    requestsCapacity: 3,
    requestKey: counselingId,
    ...meta,
  },
}));

export const recentlyClinicCounselings = createRequestAction<{ patientId: number }, ClinicCounselingConnection>(
  "requests/clinicCounseling/recentlyClinicCounselings",
  ({ patientId }, meta) => ({
    request: {
      promise: clinicCounselingApi.getCounselings({
        patientId,
        limit: 3,
      }),
    },
    meta: {
      requestsCapacity: 3,
      ...meta,
    },
  })
);

export const summaryClinicCounselings = createRequestAction<{ patientId: number }, ClinicCounselingConnection>(
  "requests/clinicCounseling/summaryClinicCounselings",
  ({ patientId }, meta) => ({
    request: {
      promise: clinicCounselingApi.getCounselings({
        patientId,
        limit: 10,
      }),
    },
    meta: {
      requestsCapacity: 3,
      ...meta,
    },
  })
);

export const cancelClinicCounseling = createRequestAction<{ counselingId: string }, ClinicCounseling>(
  "requests/clinicCounseling/cancelClinicCounseling",
  ({ counselingId }, meta) => ({
    request: {
      promise: clinicCounselingApi.patchCounseling({
        counselingId: toInteger(counselingId),
        patchCounselingRequestBody: {
          status: PatchCounselingRequestBodyStatusEnum.Canceled,
        },
      }),
    },
    meta: {
      requestKey: counselingId,
      onSuccess: (response) => {
        toastInfo("予約をキャンセルしました。");
        return response;
      },
      ...meta,
    },
  })
);

export const searchClinicCounselings = createRequestAction<ClinicCounselingSearchParams, ClinicCounselingConnection>(
  "requests/clinicCounseling/searchClinicCounselings",
  (params, meta) => ({
    request: {
      promise: clinicCounselingApi.getCounselings(params),
    },
    meta: {
      requestsCapacity: 3,
      ...meta,
    },
  })
);
