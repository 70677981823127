import { PatientServiceNeedsCommunicationEnum } from "@mgdx/api/lib/patient/patient";
import {
  GetCounselingsAutoLinkStatusEnum as SearchAutoLinkStatusEnum,
  GetCounselingsBillingStatusEnum as SearchBillingStatusEnum,
  GetCounselingsDispensingStatusEnum as SearchDispensingStatusEnum,
  GetCounselingsFollowUpStatusEnum as SearchFollowUpNotificationEnum,
  GetCounselingsOrderByEnum as SearchPharmacyCounselingOrderByEnum,
  GetCounselingsRequest,
  GetCounselingsRequestTypeEnum as SearchRequestTypeEnum,
  GetCounselingsStatusEnum as SearchPharmacyCounselingStatusEnum,
  GetCounselingsTypeEnum as SearchCounselingTypeEnum,
  PatchCounselingRequestBodyDispensingTypeEnum as UpdateDispensingTypeEnum,
  PatchCounselingRequestBodyPharmacyMessageTypeEnum as UpdatePharmacyMessageTypeEnum,
  PatchCounselingRequestBodyStatusEnum as UpdateCounselingStatusEnum,
  PharmacyCounselorBilling,
  PharmacyCounselorBillingPaymentMethodEnum as BillingPaymentMethodEnum,
  PharmacyCounselorBillingStatusEnum as BillingStatusEnum,
  PharmacyCounselorCounseling,
  PharmacyCounselorCounselingHasMedicineNotebookEnum as MedicineNotebookEnum,
  PharmacyCounselorCounselingPatient,
  PharmacyCounselorCounselingPatientStatusEnum as PatientStatusEnum,
  PharmacyCounselorCounselingRequestBillingPaymentMethodEnum as BillingPaymentEnum,
  PharmacyCounselorCounselingRequestTypeEnum as CounselingRequestTypeEnum,
  PharmacyCounselorCounselings,
  PharmacyCounselorCounselingStatusEnum as PharmacyCounselingStatusEnum,
  PharmacyCounselorCounselingTypeEnum as CounselingTypeEnum,
  PharmacyCounselorCounselingWaitingLabelEnum as CounselingWaitingLabelEnum,
  PharmacyCounselorCounselingWantsGenericEnum as WantsGenericEnum,
  PharmacyCounselorDeliveryAddressTypeEnum as DeliveryAddressTypeEnum,
  PharmacyCounselorDeliveryUpdatableAddressTypeEnum as UpdateDeliveryAddressTypeEnum,
  PharmacyCounselorDispensingStatusEnum as DispensingStatusEnum,
  PharmacyCounselorDispensingTypeEnum as DispensingTypeEnum,
  PharmacyCounselorExaminer,
  PharmacyCounselorExaminerGenderEnum as ExaminerGenderEnum,
  PharmacyCounselorFollowUp,
  PharmacyCounselorFollowUpFollowUpTypeEnum as FollowUpTypeEnum,
  PharmacyCounselorFollowUpStatusEnum as FollowUpStatusEnum,
  PharmacyCounselorInterviewInterviewsTypeEnum as InterviewsTypeEnum,
  PharmacyCounselorPatchCounselingRequestExaminerGenderEnum as UpdateExaminerGenderEnum,
  PharmacyCounselorPharmacist,
  PharmacyCounselorPrescription,
  PharmacyCounselorPrescriptionRegistrableTypeEnum as RegisterPrescriptionTypeEnum,
  PharmacyCounselorPrescriptionTypeEnum as PrescriptionTypeEnum,
  PharmacyCounselorServiceNeedsCommunicationEnum,
  PostCounselingRequestBodyDispensingTypeEnum as RegisterDispensingTypeEnum,
  PostCounselingRequestBodyHasMedicineNotebookEnum as RegisterMedicineNotebookEnum,
  PostCounselingRequestBodyRequestTypeEnum as RegisterRequestTypeEnum,
  PostCounselingRequestBodyTypeEnum as RegisterCounselingTypeEnum,
  PostCounselingRequestBodyWantsGenericEnum as RegisterWantsGenericEnum,
  PutBillingRequestBodyPaymentMethodEnum as RegisterBillingPaymentMethodEnum,
  PutBillingStatusRequestBodyStatusEnum as UpdateBillingStatusEnum,
  PutMedicalInformationResponse,
} from "@mgdx/api/lib/pharmacy/counselor";
import { Variant as ChipVariant } from "@mgdx/ui/components/Chip";
import moment from "@mgdx-libs/moment";

import { CounselingStatusType } from "../.mocks/mockPharmacyCounselingStatus";
import { dateToLongText, dateToText, DateToTextOptions } from "../utils/dateToText";
import parseDate from "../utils/parseDate";
import { useIsMccm } from "./../../../pharmacy/src/hooks/useIsMccm";
import {
  birthdayToAdminText,
  fullExaminerName,
  fullName,
  PatientHasMedicineNotebookEnum,
  PatientWantsGenericEnum,
} from "./Patient";
import { Pharmacy } from "./Pharmacy";
import { getPharmacyCounselingStatus, PHARMACY_COUNSELING_STATUS } from "./pharmacy/PharmacyCounselingStatus";
import { PharmacyCustomInterview } from "./PharmacyCustomInterview";
import {
  answerText,
  COPY_ORDER_IDS,
  genderToText,
  PharmacyCustomInterviewInterview,
} from "./PharmacyCustomInterviewInterview";
import { PharmacyInterviewQuestionnaireFromJSON } from "./PharmacyInterviewQuestionnaire";

export type PharmacyCounseling = PharmacyCounselorCounseling;
export type PharmacyCounselingSearchParams = GetCounselingsRequest;
export type PharmacyCounselingConnection = PharmacyCounselorCounselings;
export type PharmacyBilling = PharmacyCounselorBilling;
export type UpdateMedicalInformationResponse = PutMedicalInformationResponse;
export type PharmacyPatient = PharmacyCounselorCounselingPatient;
export type PharmacyExaminer = PharmacyCounselorExaminer;
export type PharmacyPharmacist = PharmacyCounselorPharmacist;
export type PharmacyPrescription = PharmacyCounselorPrescription;
export {
  BillingPaymentEnum,
  BillingPaymentMethodEnum,
  BillingStatusEnum,
  CounselingRequestTypeEnum,
  CounselingTypeEnum,
  CounselingWaitingLabelEnum,
  DeliveryAddressTypeEnum,
  DispensingStatusEnum,
  DispensingTypeEnum,
  ExaminerGenderEnum,
  FollowUpStatusEnum,
  FollowUpTypeEnum,
  InterviewsTypeEnum,
  MedicineNotebookEnum,
  PatientStatusEnum,
  PharmacyCounselingStatusEnum,
  PrescriptionTypeEnum,
  RegisterBillingPaymentMethodEnum,
  RegisterCounselingTypeEnum,
  RegisterDispensingTypeEnum,
  RegisterMedicineNotebookEnum,
  RegisterPrescriptionTypeEnum,
  RegisterRequestTypeEnum,
  RegisterWantsGenericEnum,
  SearchAutoLinkStatusEnum,
  SearchBillingStatusEnum,
  SearchCounselingTypeEnum,
  SearchDispensingStatusEnum,
  SearchFollowUpNotificationEnum,
  SearchPharmacyCounselingOrderByEnum,
  SearchPharmacyCounselingStatusEnum,
  SearchRequestTypeEnum,
  UpdateBillingStatusEnum,
  UpdateCounselingStatusEnum,
  UpdateDeliveryAddressTypeEnum,
  UpdateDispensingTypeEnum,
  UpdateExaminerGenderEnum,
  UpdatePharmacyMessageTypeEnum,
  WantsGenericEnum,
};

export const defaultReservationConnectionData: Omit<PharmacyCounselingConnection, "__typename"> = {
  count: 0,
  counselings: [],
};

export const situations = ["user", "pharmacy"] as const;

export const statusEnumToDateSuffix = (status?: PharmacyCounselingStatusEnum): string => {
  switch (status) {
    case PharmacyCounselingStatusEnum.Canceled:
      return "";
    case PharmacyCounselingStatusEnum.Requested:
      return "〜";
    case PharmacyCounselingStatusEnum.Accepted:
    case PharmacyCounselingStatusEnum.Waiting:
      return "";
  }

  return "";
};

export const statusEnumToDateLabelSuffix = (status?: PharmacyCounselingStatusEnum): string => {
  switch (status) {
    case PharmacyCounselingStatusEnum.Canceled:
      return "";
    case PharmacyCounselingStatusEnum.Requested:
      return "希望";
    case PharmacyCounselingStatusEnum.Accepted:
    case PharmacyCounselingStatusEnum.Waiting:
      return "確定";
  }

  return "";
};

export const detailParagraph = ({
  situation,
  counseling,
}: {
  situation: (typeof situations)[number];
  counseling?: PharmacyCounseling;
}): string => {
  if (!counseling) return "";

  const parts: string[] = [];

  if (
    counseling.status === PharmacyCounselingStatusEnum.Accepted ||
    counseling.status === PharmacyCounselingStatusEnum.Waiting
  ) {
    parts.push(statusDateToText(counseling.time, counseling.status));
  } else if (
    counseling.status === PharmacyCounselingStatusEnum.Requested &&
    counseling.preferredTimes &&
    counseling.preferredTimes.length > 0
  ) {
    parts.push(`${statusDateToText(counseling.preferredTimes[0], counseling.status)}〜希望`);
  }

  parts.push(kindToText({ situation, counseling }));
  parts.push(fullName(counseling.patient.examiner));

  return parts.join("・");
};

export const kindToLongText = (counseling?: PharmacyCounseling): string => {
  if (!counseling) return "";

  switch (counseling.type) {
    case CounselingTypeEnum.Dispensing:
      return "処方せん事前送信";
    case CounselingTypeEnum.DirectVisit:
    case CounselingTypeEnum.FollowUpOnly:
      return "店頭受付";
    case CounselingTypeEnum.OnlineCounseling:
      return "オンライン服薬指導";
    case CounselingTypeEnum.DeliveryOnly:
      return "配送";
  }
};

export const kindToText = ({
  situation,
  counseling,
}: {
  situation: (typeof situations)[number];
  counseling?: PharmacyCounseling;
}): string => {
  if (!counseling) return "";

  switch (counseling.type) {
    case CounselingTypeEnum.Dispensing:
      return "処方せん";
    case CounselingTypeEnum.DirectVisit:
    case CounselingTypeEnum.FollowUpOnly:
      return "店頭受付";
    case CounselingTypeEnum.OnlineCounseling:
      return situation === "user" ? "オンライン服薬指導" : "OL服薬";
    case CounselingTypeEnum.DeliveryOnly:
      return "配送";
  }
};

const noPreferredTimeText = ({
  service,
  counselingType,
  requestedDate,
  status,
}: {
  service: "user" | "pharmacy";
  counselingType?: CounselingTypeEnum;
  requestedDate?: string;
  status?: PharmacyCounselingStatusEnum;
}) => {
  const date = parseDate(requestedDate);
  switch (counselingType) {
    case CounselingTypeEnum.Dispensing:
      return "1時間以内";
    case CounselingTypeEnum.OnlineCounseling: {
      const year =
        status === PharmacyCounselingStatusEnum.Canceled || date?.year() !== moment().year() ? `${date?.year()}年` : "";
      return service === "user"
        ? `${year}${moment(requestedDate).format("M月D日(ddd)")} 時間指定しない`
        : `${year}${moment(requestedDate).format("M月D日(ddd)")} 時間指定なし`;
    }
    default:
      return "";
  }
};

export const statusDateToText = (
  stringDate?: string,
  status?: PharmacyCounselingStatusEnum,
  counselingType?: CounselingTypeEnum,
  requestedDate?: string,
  option?: DateToTextOptions
): string => {
  const date = parseDate(stringDate);
  switch (status) {
    case PharmacyCounselingStatusEnum.Canceled:
      return stringDate
        ? dateToLongText(stringDate, option)
        : noPreferredTimeText({ service: "user", counselingType, requestedDate, status });
    case PharmacyCounselingStatusEnum.Requested:
      if (!stringDate) {
        return noPreferredTimeText({ service: "user", counselingType, requestedDate });
      }

      if (date?.year() === moment().year()) {
        return dateToText(stringDate, option);
      } else {
        dateToLongText(stringDate, option);
      }
    case PharmacyCounselingStatusEnum.Accepted:
    case PharmacyCounselingStatusEnum.Waiting:
      if (date?.year() === moment().year()) {
        return dateToText(stringDate, option);
      }
  }

  return dateToLongText(stringDate, option);
};

export const preferredTimesToTexts = (
  service: "user" | "pharmacy",
  preferredTimes?: string[],
  counselingType?: CounselingTypeEnum,
  requestedDate?: string,
  option?: DateToTextOptions
): string[] => {
  if (!preferredTimes || !Array.isArray(preferredTimes) || preferredTimes.length === 0)
    return [noPreferredTimeText({ service, counselingType, requestedDate })];

  const suffix = option?.isForAccessibility ? "" : " 〜";

  if (preferredTimes.length > 1) {
    return preferredTimes.map(
      (preferredTime, index) => `第${index + 1}希望 : ${dateToText(preferredTime, option)}${suffix}`
    );
  } else {
    return preferredTimes.map((preferredTime) => `${dateToText(preferredTime, option)}${suffix}`);
  }
};

export const preferredTimeToText = (
  service: "user" | "pharmacy",
  stringDate?: string,
  counselingType?: CounselingTypeEnum,
  requestedDate?: string,
  option?: DateToTextOptions
): string => {
  const date = dateToText(stringDate, option);

  if (date !== "") {
    return `${date} 〜`;
  }

  return noPreferredTimeText({ service, counselingType, requestedDate });
};

export const wantGenericToText = (
  wantGeneric?: WantsGenericEnum | RegisterWantsGenericEnum | PatientWantsGenericEnum | string
): string => {
  switch (wantGeneric) {
    case "consultation":
      return "どちらでもよい";
    case "want":
      return "希望する";
    case "none":
      return "希望しない";
  }
  return "未設定";
};

export const serviceNeedsToText = (
  serviceNeeds?: PharmacyCounselorServiceNeedsCommunicationEnum | PatientServiceNeedsCommunicationEnum
): string => {
  switch (serviceNeeds) {
    case "normal":
      return "通常の説明を希望する";
    case "careful":
      return "丁寧で詳しい説明を希望する";
    default:
      return "未設定";
  }
};

export const hasMedicineNotebookToText = (
  hasMedicineNotebook?: MedicineNotebookEnum | RegisterMedicineNotebookEnum | PatientHasMedicineNotebookEnum | string
): string => {
  switch (hasMedicineNotebook) {
    case "yqb":
      return "利用する (薬急便お薬手帳)";
    case "digital":
      return "利用する (外部スマホアプリ)";
    case "paper":
      return "利用する (紙冊子)";
    case "none":
      return "利用しない";
  }

  return "未設定";
};

const CounselingResponseType = ["messages", "hints"] as const;

export const counselingResponseToText = (
  responseType: (typeof CounselingResponseType)[number],
  status: number
): string => {
  if (responseType === "hints") {
    switch (status) {
      case 400:
        return "担当薬剤師が開始するまでお待ちください";
      case 404:
        return "予約詳細へ戻り予約内容をご確認ください";
      case 500:
        return "しばらく経ってから再度お試しください。";
      case 503:
        return "しばらく経ってから再度お試しください。";
    }
  } else {
    switch (status) {
      case 400:
        return "服薬指導が開始されていません";
      case 404:
        return "服薬指導を開始できませんでした";
      case 500:
        return "服薬指導が開始できませんでした";
      case 503:
        return "服薬指導が開始できませんでした";
    }
  }

  return "";
};

export const DeliveryAddressTypeEnumToText = (
  status?: DeliveryAddressTypeEnum | UpdateDeliveryAddressTypeEnum
): string => {
  switch (status) {
    case DeliveryAddressTypeEnum.Registered:
    case UpdateDeliveryAddressTypeEnum.Registered:
      return "ご登録の住所へ配送";
    case DeliveryAddressTypeEnum.Specified:
    case UpdateDeliveryAddressTypeEnum.Specified:
      return "指定場所へ配送";
    case DeliveryAddressTypeEnum.PickUp:
    case UpdateDeliveryAddressTypeEnum.PickUp:
      return "店頭受け取り";
  }

  return "";
};

export const examinerGenderEnumToText = (status?: ExaminerGenderEnum | UpdateExaminerGenderEnum): string => {
  switch (status) {
    case ExaminerGenderEnum.Male:
    case UpdateExaminerGenderEnum.Male:
      return "男性";
    case ExaminerGenderEnum.Female:
    case UpdateExaminerGenderEnum.Female:
      return "女性";
    case ExaminerGenderEnum.None:
    case UpdateExaminerGenderEnum.None:
      return "未設定";
  }

  return "未設定";
};

type FollowupStatusToText = (
  situation: (typeof situations)[number],
  followupStatus?: FollowUpStatusEnum | SearchFollowUpNotificationEnum
) => string;

export const followupStatusToText: FollowupStatusToText = (situation, followupStatus) => {
  switch (followupStatus) {
    case SearchFollowUpNotificationEnum.Initialized:
    case FollowUpStatusEnum.Initialized:
      return "未登録";
    case SearchFollowUpNotificationEnum.Registered:
    case FollowUpStatusEnum.Registered:
      return "登録済み";
    case SearchFollowUpNotificationEnum.Notified:
    case FollowUpStatusEnum.Notified:
      return situation === "user" ? "服薬状況回答待ち" : "送信済み";
    case SearchFollowUpNotificationEnum.Answered:
    case FollowUpStatusEnum.Answered:
      return situation === "user" ? "回答済み" : "患者回答済み";
    case SearchFollowUpNotificationEnum.AnswerConfirmed:
    case FollowUpStatusEnum.AnswerConfirmed:
      return "回答確認済み";
    case SearchFollowUpNotificationEnum.Canceled:
    case FollowUpStatusEnum.Canceled:
      return "取消";
    case SearchFollowUpNotificationEnum.Completed:
    case FollowUpStatusEnum.Completed:
      return "完了";
  }

  return "";
};

export const createFollowUpToCopyText = ({
  followUp,
  counseling,
}: {
  followUp?: PharmacyCounselorFollowUp;
  counseling?: PharmacyCounseling;
}) => `# 予約管理番号
${counseling?.managementNo}
# フォローアップ回答日
${dateToLongText(followUp?.answeredTime, { medical: true })}
# フォローアップ回答内容
${followUp?.questionnaires
  ?.map((questionnaire) => {
    const answers = followUp.answers?.filter((a) => a.questionnaireId === questionnaire.id);
    const values = answers?.map((a) => questionnaire.options?.find((o) => o.id === a.optionId));
    const freeEntry = answers?.find((a) => a.freeEntry !== undefined);
    if (!values) return "- " + [questionnaire.question, "未回答"].join("\n");
    return (
      "- " +
      [questionnaire.question, values.map((value) => value?.optionName).join(","), freeEntry?.freeEntry]
        .filter(Boolean)
        .join("\n")
    );
  })
  .join("\n")}
`;

export const createCustomInterviewToCopyText = (
  interviews: PharmacyCustomInterviewInterview[],
  counseling?: PharmacyCounseling
) => `# 予約管理番号
${counseling?.managementNo}

# 服薬質問回答内容
${interviews
  .map((interview) => {
    const label = `## ${interview.label}`;
    const answers = answerText(interview) || "未回答";
    const questionnaireOptionsAnswers = interview.questionnaireOptions.map((option) => {
      const label = `### ${option.label}`;
      const answers = answerText(option) || "未回答";
      return label + "\n" + answers + "\n";
    });
    return label + "\n" + answers + "\n" + questionnaireOptionsAnswers.join("\n");
  })
  .join("\n\n")}
`;

export const createCustomInterviewToCopyText2 = (
  interviews: PharmacyCustomInterview,
  counseling?: PharmacyCounseling
) => {
  const patient = interviews.patient;
  const allInterviews = [
    ...interviews.customInterview.interviews,
    ...(interviews.customInterview.lifestyleHabits ?? []),
  ];

  return `
# 患者情報
## 患者氏名
${fullExaminerName(counseling?.patient) ?? "未回答"}

## 生年月日
${patient.birthDay ? birthdayToAdminText(patient.birthDay) : "未回答"}

## 性別
${genderToText(patient.sex) ?? "未回答"}

## 身長(cm)
${patient.height ? `${patient.height}cm` : "未回答"}

## 小児(13歳未満)の体重
${patient.childWeight ? `${patient.childWeight}kg` : "未回答"}

## ジェネリック医薬品の希望
${counseling ? wantGenericToText(counseling.wantsGeneric) : "未回答"}

## お薬手帳の利用
${counseling ? hasMedicineNotebookToText(counseling.hasMedicineNotebook) : "未回答"}

# 予約管理番号
${counseling?.managementNo}

# 服薬質問回答内容
${COPY_ORDER_IDS.map((id) => {
  const interview = allInterviews.find((item) => item.id === id);
  return interview ?? undefined;
})
  .map((interview) => {
    if (!interview) return "";
    const label = `## ${interview.label}`;
    const answers = answerText(interview) || "未回答";
    const questionnaireOptionsAnswers = interview.questionnaireOptions.map((option) => {
      const label = `### ${option.label}`;
      const answers = answerText(option) || "未回答";
      return label + "\n" + answers + "\n";
    });
    return label + "\n" + answers + "\n" + questionnaireOptionsAnswers.join("");
  })
  .join("\n")}
`;
};

export enum SendFollowUpPatternEnum {
  SMS = "sms",
  Mail = "mail",
  PushAndMail = "push-and-mail",
  LINEOfficialAccount = "line-official-account",
  LINEServiceMessage = "line-service-message",
}

export const getSendFollowUpPattern = (
  pharmacy: Pharmacy,
  counseling?: PharmacyCounseling | undefined
): SendFollowUpPatternEnum => {
  // TODO: useIsMccmをhooksをやめて、普通のメソッドにする
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isMccmChain = useIsMccm({ pharmacy });
  const isDigitalMember = !!counseling?.patient.externalIdV2?.digitalMemberId;

  if (isMccmChain) {
    if (isDigitalMember) return SendFollowUpPatternEnum.PushAndMail;
    return SendFollowUpPatternEnum.Mail;
  }

  if (!counseling) return SendFollowUpPatternEnum.SMS;

  if (counseling.requestType === CounselingRequestTypeEnum.Web) return SendFollowUpPatternEnum.SMS;
  if (isLineOfficialFriendship(pharmacy.hasLineOfficialAccount, counseling))
    return SendFollowUpPatternEnum.LINEOfficialAccount;

  return SendFollowUpPatternEnum.LINEServiceMessage;
};

export const sendFollowUpPatternToText = (pattern: SendFollowUpPatternEnum): string => {
  switch (pattern) {
    case SendFollowUpPatternEnum.SMS:
      return "SMS";
    case SendFollowUpPatternEnum.LINEOfficialAccount:
      return "LINE公式アカウント";
    case SendFollowUpPatternEnum.LINEServiceMessage:
      return "LINEサービスメッセージ";
    case SendFollowUpPatternEnum.PushAndMail:
      return "プッシュ通知/メール";
    case SendFollowUpPatternEnum.Mail:
      return "メール";
  }
};

export const isLineOfficialFriendship = (
  hasLineOfficialAccount: boolean | undefined,
  counseling?: PharmacyCounseling | undefined
): boolean => {
  if (!counseling) return false;

  const friendship = counseling.optionalInformation
    ? counseling.optionalInformation["line_official_account_friend"]
    : false;

  if (friendship && hasLineOfficialAccount) return true;

  return false;
};

type ReturnTypeDispensingStatusEnumToText<S> = S extends "user"
  ? "受付済み" | "調剤済み" | "お渡し済み"
  : "未対応" | "調剤完了" | "患者連絡済み" | "お渡し済み";
export const dispensingStatusEnumToText = (
  situation: (typeof situations)[number],
  status?: DispensingStatusEnum
): ReturnTypeDispensingStatusEnumToText<(typeof situations)[number]> | "未設定" => {
  if (situation === "user") {
    switch (status) {
      case DispensingStatusEnum.Initialized:
        return "受付済み";
      case DispensingStatusEnum.Dispensed:
        return "調剤済み";
      case DispensingStatusEnum.Notified:
        return "調剤済み";
      case DispensingStatusEnum.Completed:
        return "お渡し済み";
    }
  } else {
    switch (status) {
      case DispensingStatusEnum.Initialized:
        // ここを受付済みにすればいい気がする
        return "未対応";
      case DispensingStatusEnum.Dispensed:
        return "調剤完了";
      case DispensingStatusEnum.Notified:
        return "患者連絡済み";
      case DispensingStatusEnum.Completed:
        return "お渡し済み";
    }
  }

  return "未設定";
};

export const billingStatusEnumToText = (situation: (typeof situations)[number], status?: BillingStatusEnum): string => {
  const isUserSituation = situation === "user";

  switch (status) {
    case BillingStatusEnum.Registered:
      return isUserSituation ? "決済待ち" : "請求処理待ち";
    case BillingStatusEnum.Started:
      return isUserSituation ? "決済手続き中" : "請求中";
    case BillingStatusEnum.Succeeded:
      return isUserSituation ? "決済完了" : "決済完了";
    case BillingStatusEnum.Failed:
      return isUserSituation ? "決済エラー" : "決済エラー";
    case BillingStatusEnum.Canceled:
      return isUserSituation ? "決済キャンセル" : "請求処理待ち";
  }

  return "";
};

export const billingPaymentEnumToText = (type?: BillingPaymentMethodEnum): string => {
  switch (type) {
    case BillingPaymentMethodEnum.Counter:
      return "店頭決済";
    case BillingPaymentMethodEnum.Online:
      return "薬急便決済";
    case BillingPaymentMethodEnum.MedicalSupport:
      return "";
  }

  return "";
};

export const isBillingInitialized = (counseling: PharmacyCounseling | undefined) => {
  if (!counseling) return true;
  if (!counseling.billing) return true;
  if (counseling.billing.status === BillingStatusEnum.Initialized) return true;

  return false;
};

export const convertDefaultCounselingQuestionnaires = (counseling: PharmacyCounseling | undefined) => {
  if (!counseling) return [];
  if (!counseling.interview) return [];

  if (!counseling.interview["questionnaires"]) return [];

  const questionnaire = counseling.interview["questionnaires"]["questionnaires"];
  if (!Array.isArray(questionnaire)) return [];

  return questionnaire.map((v) => PharmacyInterviewQuestionnaireFromJSON(v));
};

export const convertBillingPaymentMethodEnumToRegisterType = (paymentMethod: BillingPaymentMethodEnum) => {
  switch (paymentMethod) {
    case BillingPaymentMethodEnum.Counter:
      return RegisterBillingPaymentMethodEnum.Counter;
    case BillingPaymentMethodEnum.Online:
      return RegisterBillingPaymentMethodEnum.Online;
    case BillingPaymentMethodEnum.MedicalSupport:
      return RegisterBillingPaymentMethodEnum.MedicalSupport;
  }
};

export type CounselingStatusOptions =
  | {
      status: PharmacyCounselingStatusEnum;
      searchStatus?: undefined;
      dispensingStatus?: DispensingStatusEnum;
      billingStatus?: BillingStatusEnum;
      followupStatus?: FollowUpStatusEnum;
      statusDetail?: string;
    }
  | {
      status?: undefined;
      searchStatus: SearchPharmacyCounselingStatusEnum;
      dispensingStatus?: DispensingStatusEnum;
      billingStatus?: BillingStatusEnum;
      followupStatus?: FollowUpStatusEnum;
      statusDetail?: string;
    };

export const counselingStatusToStatusList = (counselingStatus: CounselingStatusType): CounselingStatusOptions => ({
  status: counselingStatus.status,
  dispensingStatus: counselingStatus.dispensing?.status,
  billingStatus: counselingStatus.billing?.status,
  followupStatus: counselingStatus.followUp?.status,
});

export const counselingStatusEnumToLabel = (
  situation: (typeof situations)[number],
  { status, searchStatus, dispensingStatus, billingStatus, followupStatus, statusDetail }: CounselingStatusOptions
): {
  text: string;
  variant: ChipVariant;
} => {
  const isUserSituation = situation === "user";
  const counselingStatus = (status || searchStatus) as unknown as PharmacyCounselingStatusEnum;

  const pharmacyCounselingStatus = getPharmacyCounselingStatus({
    status: counselingStatus,
    ...(dispensingStatus ? { dispensing: { status: dispensingStatus } } : {}),
    ...(billingStatus ? { billing: { status: billingStatus } } : {}),
    ...(followupStatus ? { followUp: { status: followupStatus } } : {}),
  } as PharmacyCounseling);

  switch (pharmacyCounselingStatus) {
    case PHARMACY_COUNSELING_STATUS.RESERVATION_REQUESTED:
      return { text: isUserSituation ? "予約依頼中" : "予約依頼", variant: "accent_primary" };

    case PHARMACY_COUNSELING_STATUS.RESERVATION_ACCEPTED:
      return { text: "受付済み", variant: "accent_tertiary" };

    case PHARMACY_COUNSELING_STATUS.DISPENSING:
    case PHARMACY_COUNSELING_STATUS.DISPENSING_WITH_NOTIFIED:
      return { text: dispensingStatusEnumToText(situation, dispensingStatus), variant: "accent_tertiary" };

    case PHARMACY_COUNSELING_STATUS.RESERVATION_CANCELED:
      return isUserSituation
        ? { text: statusDetail === "自動" ? "予約キャンセル (自動)" : "予約キャンセル", variant: "weak" }
        : { text: statusDetail ? `予約取消 (${statusDetail})` : "予約取消", variant: "weak" };

    case PHARMACY_COUNSELING_STATUS.BILLING_PROCESSING:
    case PHARMACY_COUNSELING_STATUS.BILLING_REQUESTED:
      return isUserSituation
        ? { text: "決済待ち", variant: "caution" }
        : { text: "請求処理待ち", variant: "accent_tertiary" };

    case PHARMACY_COUNSELING_STATUS.BILLING_STARTED:
      return isUserSituation
        ? { text: "決済手続き中", variant: "caution" }
        : { text: "請求中", variant: "accent_tertiary" };

    case PHARMACY_COUNSELING_STATUS.BILLING_FAILED:
      return { text: "決済エラー", variant: "caution" };

    case PHARMACY_COUNSELING_STATUS.BILLING_CANCELED:
      return isUserSituation
        ? { text: "決済キャンセル", variant: "caution" }
        : { text: "請求処理待ち", variant: "accent_tertiary" };

    case PHARMACY_COUNSELING_STATUS.FOLLOW_UP_ASKED:
      return isUserSituation
        ? { text: "服薬状況回答待ち", variant: "accent_secondary" }
        : { text: "決済完了", variant: "weak" };

    case PHARMACY_COUNSELING_STATUS.BILLING_COMPLETED:
      return { text: "決済完了", variant: "weak" };

    case PHARMACY_COUNSELING_STATUS.ONLINE_COUNSELING_READY:
      return { text: "服薬指導待ち", variant: "accent_secondary" };

    case PHARMACY_COUNSELING_STATUS.ONLINE_COUNSELING_STARTED:
      return { text: "服薬指導中", variant: "accent_secondary" };

    case PHARMACY_COUNSELING_STATUS.ONLINE_COUNSELING_COMPLETED:
      return { text: "服薬指導済み", variant: isUserSituation ? "accent_secondary" : "caution" };
  }
};

export const getWaitingLabelText = (waitingLabel: CounselingWaitingLabelEnum) => {
  switch (waitingLabel) {
    case CounselingWaitingLabelEnum.Hurry:
      return "急ぎ";
    case CounselingWaitingLabelEnum.GoOut:
      return "外出";
    case CounselingWaitingLabelEnum.AfterTomorrow:
      return "後日";
    case CounselingWaitingLabelEnum.Ambiguous:
      return "疑義";
    case CounselingWaitingLabelEnum.HasContact:
      return "声掛済";
    case CounselingWaitingLabelEnum.UrgencyLow:
      return "軽";
    case CounselingWaitingLabelEnum.UrgencyMiddle:
      return "中";
    case CounselingWaitingLabelEnum.UrgencyHigh:
      return "重";

    default:
      return "";
  }
};
