import BaseButton from "@mgdx/ui/components/core/BaseButton";
import { toastError } from "@mgdx/ui/components/Toast";
import React, { RefObject, useCallback, useId } from "react";
import { DropEvent, DropzoneOptions, useDropzone } from "react-dropzone";

export type FileObject = {
  file: File;
  data?: string;
};

export type Props = Omit<DropzoneOptions, "onDropAccepted" | "onDropRejected" | "onDrop" | "multiple" | "onError"> &
  Pick<React.InputHTMLAttributes<HTMLInputElement>, "title" | "capture"> & {
    onChangeFile?: (file: File) => void;
    icon?: React.ReactNode;
  };

const InputFile = ({
  /**
   * @see https://react-dropzone.js.org/#src
   * DropzoneOptions
   */
  accept,
  maxSize,
  minSize,
  noClick,
  noDrag,
  noDragEventsBubbling,
  noKeyboard,
  onDragEnter,
  onDragLeave,
  onDragOver,
  preventDropOnDocument,
  /**
   * my props
   */
  onChangeFile,
  icon,
  ...props
}: Props) => {
  const controlId = useId();

  const onDropAccepted = useCallback<NonNullable<DropzoneOptions["onDropAccepted"]>>(
    async (acceptedFiles: File[], event: DropEvent) => {
      onChangeFile && onChangeFile(acceptedFiles[0]);
    },
    [onChangeFile]
  );

  const onDropRejected = useCallback<NonNullable<DropzoneOptions["onDropRejected"]>>(async (fileRejections, event) => {
    toastError("このファイルは選択できません。");
  }, []);

  const { getRootProps, getInputProps, rootRef } = useDropzone({
    accept,
    maxSize,
    minSize,
    multiple: false,
    noClick: true,
    noDrag,
    noDragEventsBubbling,
    noKeyboard,
    onDragEnter,
    onDragLeave,
    onDragOver,
    onDropAccepted,
    onDropRejected,
    preventDropOnDocument,
  });

  return (
    <BaseButton
      fab={true}
      variant="tertiary-light"
      ref={rootRef as RefObject<HTMLDivElement>}
      {...getRootProps({ style: { outline: "none" } })}
    >
      <label htmlFor={controlId}>
        {icon}
        <input id={controlId} type="file" {...props} {...getInputProps()} />
      </label>
    </BaseButton>
  );
};

export default InputFile;
