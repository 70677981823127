import { ErrorBoundary, errorHandlerReport, initSentry } from "@mgdx-libs/error-handler";
import { PersistGate } from "@mgdx-libs/redux-persist-ssr";
import { globalHistory, Location } from "@mgdx-libs/router";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { GatsbyBrowser, GatsbySSR } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import { Provider } from "react-redux";
import { QueryParamProvider } from "use-query-params";

import App from "./App";
import { AuthProvider } from "./AuthProvider";
import configureStore from "./configureStore";
import { useFocusAndBlurObserver } from "./hooks/useFocusAndBlurObserver";

const { store, persistor } = configureStore();
const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      errorHandlerReport(error);
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      errorHandlerReport(error);
    },
  }),
});

initSentry({ dsn: process.env.SENTRY_DSN });

const RootElement: React.FC<React.PropsWithChildren<EmptyObject>> = ({ children }) => {
  useFocusAndBlurObserver();

  return (
    <ErrorBoundary errorHandlerReport={errorHandlerReport}>
      <Location>
        {({ location }) => (
          <QueryParamProvider location={location} reachHistory={globalHistory}>
            <QueryClientProvider client={queryClient}>
              <Provider store={store}>
                <PersistGate loading={null} persistor={persistor as any}>
                  <Helmet>
                    <html lang="ja" />
                    <title>薬急便</title>
                    <meta
                      name="viewport"
                      content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
                    />
                  </Helmet>
                  <AuthProvider store={store}>
                    <App>{children}</App>
                  </AuthProvider>
                </PersistGate>
              </Provider>
            </QueryClientProvider>
          </QueryParamProvider>
        )}
      </Location>
    </ErrorBoundary>
  );
};

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] & GatsbySSR["wrapRootElement"] = ({ element }) => (
  <RootElement>{element}</RootElement>
);
