import * as httpStatus from "http-status";

import {
  ApiBadRequestError,
  ApiCustomError,
  ApiError,
  ApiForbiddenError,
  ApiInternalServerError,
  ApiNotFoundError,
  ApiTimeoutError,
  ApiUnauthorizedError,
  ApiUnprocessableEntityError,
} from "../errors";
import { responseToErrorMessage } from "../utils/responseToErrorMessage";
import { parseErrorResponse } from "./checkErrorResponse";

export type ApiNetworkErrorHandler = (errorOrResponse: Error | Response | DOMException) => Promise<Error>;

const apiResponseStatusToError = (response: Response, detail: string) => {
  if (response.status === httpStatus.BAD_REQUEST) {
    return new ApiBadRequestError(detail);
  }

  if (response.status === httpStatus.UNAUTHORIZED) {
    return new ApiUnauthorizedError(detail);
  }

  if (response.status === httpStatus.FORBIDDEN) {
    return new ApiForbiddenError(detail);
  }

  if (response.status === httpStatus.NOT_FOUND) {
    return new ApiNotFoundError(detail);
  }

  if (response.status === httpStatus.INTERNAL_SERVER_ERROR) {
    return new ApiInternalServerError(detail);
  }

  if (response.status === httpStatus.UNPROCESSABLE_ENTITY) {
    return new ApiUnprocessableEntityError(detail);
  }

  // other response
  return new ApiError(detail);
};

export const apiErrorHandler: ApiNetworkErrorHandler = async (errorOrResponse) => {
  if (errorOrResponse instanceof DOMException && errorOrResponse.name === "AbortError") {
    return new ApiTimeoutError(errorOrResponse);
  }

  if (errorOrResponse instanceof Response) {
    const errorMessage = await responseToErrorMessage(errorOrResponse);

    return apiResponseStatusToError(errorOrResponse, errorMessage);
  }

  return errorOrResponse;
};

// apiErrorHandlerをApiCustomErrorなどにも対応するように修正したもの
export const getApiError = async (error: Response | DOMException | Error): Promise<Error> => {
  if (error instanceof DOMException && error.name === "AbortError") {
    return new ApiTimeoutError(error);
  } else if (error instanceof Response) {
    const { message, detail } = await parseErrorResponse(error);
    const apiStatusError = apiResponseStatusToError(error, detail);

    if (message) {
      return new ApiCustomError(message, { cause: apiStatusError });
    }

    return apiStatusError;
  } else {
    return error;
  }
};

export const handleApiError = async (error: Response | DOMException | Error) => {
  throw await getApiError(error);
};

export const SYSTEM_ERROR_MESSAGE = "システムエラーが発生しました。時間を置いてからお試しください。";
export const TIMEOUT_ERROR_MESSAGE = "通信がタイムアウトしました。時間を置いてからお試しください。";
export const NETWORK_ERROR_MESSAGE = "ネットワークエラーが発生しました。通信環境をご確認ください。";

export const getErrorMessage = (error: Error, customErrorPrefix?: string): string => {
  if (error instanceof ApiCustomError) {
    return customErrorPrefix ? customErrorPrefix + error.message : error.message;
    // TODO: エラーステータスなどによってエラーメッセージを変える
    // } else if (error instanceof ApiTimeoutError) {
    //   return TIMEOUT_ERROR_MESSAGE;
    // } else if (error instanceof TypeError && error.message === "Failed to fetch") {
    //   return NETWORK_ERROR_MESSAGE;
    // } else {
    //   return SYSTEM_ERROR_MESSAGE;
    // }
  } else {
    return customErrorPrefix
      ? customErrorPrefix + "しばらく経ってからお試しください。"
      : "システムエラーが発生しました。しばらく経ってからお試しください。";
  }
};
