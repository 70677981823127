import { patientApi } from "@mgdx/api/lib/patientApi";
import { pharmacyChainApi } from "@mgdx/api/lib/pharmacyChainApi";
import appName from "@mgdx/shared/src/appName";
import { Patient } from "@mgdx/shared/src/models/Patient";
import { getApiError } from "@mgdx-libs/error-handler";
import { Dispatch } from "redux";

import { setCurrentUser } from "../../ducks/currentUser";

export const getIsAppUserIdOld = async ({
  chainId,
  patient,
  appUserId,
}: {
  chainId: number;
  patient: Patient;
  appUserId: string;
}): Promise<boolean> => {
  const externalIdsV2InChain = patient.externalIdsV2?.[chainId] as { current_app_user_id: string } | undefined;
  return externalIdsV2InChain?.current_app_user_id !== appUserId;
};

export const updatePatientWithMccmAppUserId = async (
  dispatch: Dispatch,
  patient: Patient,
  appUserId: string
): Promise<Patient> => {
  const chainId = await pharmacyChainApi
    .getAllChain({ appName })
    .then((chains) => {
      if (!chains[0] || !chains[0].id) {
        throw new Error("cannot get chain id");
      }

      return chains[0].id;
    })
    .catch(async (error) => {
      throw await getApiError(error);
    });

  const isAppUserIdOld = await getIsAppUserIdOld({ chainId, patient, appUserId });

  if (!isAppUserIdOld) return patient;

  const externalIdsV2InChain = patient.externalIdsV2?.[chainId];
  const newExternalIdsV2InChain = { ...externalIdsV2InChain, current_app_user_id: appUserId };
  const externalIdsV2 = { ...patient.externalIdsV2, [chainId]: newExternalIdsV2InChain };

  const updatedPatient = await patientApi
    .updateCurrentPatient({
      patchPatientRequestBody: {
        externalIdsV2,
      },
    })
    .catch(async (errorOrResponse) => {
      throw await getApiError(errorOrResponse);
    });

  dispatch(setCurrentUser(updatedPatient));

  return updatedPatient;
};
