import { pharmacyApi } from "@mgdx/api/lib/pharmacyApi";
import { FamilyPharmacist, FamilyPharmacistIdAttributes } from "@mgdx/shared/src/models/FamilyPharmacist";
import { createRequestAction } from "@mgdx-libs/redux-requests";

export const findFamilyPharmacist = createRequestAction<FamilyPharmacistIdAttributes, FamilyPharmacist>(
  "requests/familyPharmacist/allFamilyPharmacists",
  ({ familyPharmacistId }, meta) => ({
    request: {
      promise: pharmacyApi.getFamilyPharmacist({
        familyPharmacistId,
      }),
    },
    meta,
  })
);
