import { GetInterviewResponse, PatientInterview } from "@mgdx/api/lib/patient/patient";
import { patientApi } from "@mgdx/api/lib/patientApi";
import {
  Patient,
  PatientUpdateAttributes,
  PatientUpdateInterviewAttributes,
  PatientUpdatePasswordAttributes,
} from "@mgdx/shared/src/models/Patient";
import { toastError, toastInfo } from "@mgdx/ui/components/Toast";
import { checkErrorResponse } from "@mgdx-libs/error-handler";
import { createRequestAction } from "@mgdx-libs/redux-requests";
import { toInteger } from "lodash";

import { setCurrentUserWithDispatch } from "../currentUser";

export const findCurrentUser = createRequestAction<void, Patient>("requests/user/findCurrentPatient", (_, meta) => ({
  request: {
    promise: patientApi.getCurrentPatient(),
  },
  meta: {
    cache: 10,
    onSuccess: (response, requestAction, store) => {
      setCurrentUserWithDispatch(store.dispatch, response.data);
      return response;
    },
    ...meta,
  },
}));

export const changeCurrentUserPassword = createRequestAction<PatientUpdatePasswordAttributes, Patient>(
  "requests/patient/changeCurrentUserPassword",
  (putPatientPasswordRequestBody, meta) => ({
    request: {
      promise: patientApi.putPatientPassword({
        putPatientPasswordRequestBody,
      }),
    },
    meta: {
      onSuccess: (response, action, store) => {
        store.dispatch(findCurrentUser());
        return response;
      },
      ...meta,
    },
  })
);

export const editCurrentUser = createRequestAction<PatientUpdateAttributes, Patient>(
  "requests/patient/editCurrentPatient",
  (patchPatientRequestBody, meta) => ({
    request: {
      promise: patientApi
        .updateCurrentPatient({
          patchPatientRequestBody,
        })
        .catch(async (error) => {
          const message = await checkErrorResponse(error);
          toastError(message);

          throw error;
        }),
    },
    meta: {
      onSuccess: async (response, action, store) => {
        setCurrentUserWithDispatch(store.dispatch, response.data);
        return response;
      },
      ...meta,
    },
  })
);

export const editInsuranceCard = createRequestAction<PatientUpdateAttributes, Patient>(
  "requests/patient/editInsuranceCard",
  (patchPatientRequestBody, meta) => ({
    request: {
      promise: patientApi.updateCurrentPatient({
        patchPatientRequestBody,
      }),
    },
    meta: {
      onSuccess: async (response, action, store) => {
        setCurrentUserWithDispatch(store.dispatch, response.data);
        return response;
      },
      ...meta,
    },
  })
);

export const updatePatientInterview = createRequestAction<PatientUpdateInterviewAttributes, PatientInterview>(
  "requests/patient/updatePatientInterview",
  (patchPatientInterviewRequestBody, meta) => ({
    request: {
      promise: patientApi.putInterview(patchPatientInterviewRequestBody),
    },
    meta: {
      requestKey: patchPatientInterviewRequestBody.familyAccountId?.toString(),
      onSuccess: async (response) => {
        toastInfo("登録しました。");
        return response;
      },
      onError: async (response) => {
        toastError("登録に失敗しました。");
        return response;
      },
      ...meta,
    },
  })
);

export const getPatientInterview = createRequestAction<
  { chainId: number; familyAccountId?: string },
  GetInterviewResponse
>("requests/patient/getPatientInterview", ({ chainId, familyAccountId }, meta) => ({
  request: {
    promise: patientApi.getInterview({
      chainId: chainId,
      familyAccountId: toInteger(familyAccountId),
    }),
  },
  meta: {
    requestKey: familyAccountId,
    onSuccess: async (response) => {
      return response;
    },
  },
}));
