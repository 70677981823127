import { PostVerifyTokenRequestBody, PostVerifyTokenResponse } from "@mgdx/api/lib/bff/admin";
import { recaptchaApi } from "@mgdx/api/lib/recaptchaApi";
import { createRequestAction } from "@mgdx-libs/redux-requests";

export type VerifyTokenParams = PostVerifyTokenRequestBody;

export const verifyToken = createRequestAction<VerifyTokenParams, PostVerifyTokenResponse>(
  "requests/admin/verifyToken",
  (postVerifyTokenRequestBody, meta) => ({
    request: {
      promise: recaptchaApi.postVerifyToken({
        postVerifyTokenRequestBody,
      }),
    },
    meta: {
      ...meta,
      requestKey: postVerifyTokenRequestBody.token,
    },
  })
);
