import { bffPatientApi } from "@mgdx/api/lib/bffPatientApi";
import { toastError } from "@mgdx/ui/components/Toast";
import { checkErrorResponse, errorHandlerReport } from "@mgdx-libs/error-handler";
import { clearAccessToken, signoutFirebase } from "@mgdx-libs/firebase";
import { navigate } from "@mgdx-libs/link";
import { abortRequests, clearRequestsCache, resetRequests } from "@redux-requests/core";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { clearCurrentUser } from "../ducks/currentUser";
import { canUseAppBridge } from "../utils/app-bridge/canUseAppBridge";
import { callHideBackButtonInApp } from "../utils/app-bridge/publisher";

export type DeleteUser = (args: { reasons: string[]; message: string }) => Promise<any>;

/**
 * ex
 * entry.2040870261: メッセージ
 * entry.1781500597: 利用したい薬局がない
 * entry.1781500597: サービスが使いづらい
 * entry.1781500597: 正常に動作しなかった
 * entry.1781500597_sentinel:
 * fvv: 1
 * draftResponse: [null,null,"-7748372541568244623"]
 * pageHistory: 0
 * fbzx: -7748372541568244623
 */

const GOOGLE_FORM_POST_URL =
  "https://docs.google.com/forms/u/1/d/e/1FAIpQLScA1aC0UlGoBTyL03aPImz4ZISq_Qv0ArdJMHxsT6PhrIjuLA/formResponse";
const GOOGLE_FORM_REASONS_FIELD_NAME = "entry.1781500597";
const GOOGLE_FORM_MESSAGE_FIELD_NAME = "entry.2040870261";

const useDeleteUser = ({ linkType }: { linkType: string }): DeleteUser => {
  const dispatch = useDispatch();

  return useCallback<DeleteUser>(
    async ({ reasons, message }) => {
      await bffPatientApi
        .deletePatient()
        .then(async () => {
          const formData = new FormData();
          reasons.forEach((reason) => {
            formData.append(GOOGLE_FORM_REASONS_FIELD_NAME, reason);
          });
          formData.append(GOOGLE_FORM_MESSAGE_FIELD_NAME, message);
          await fetch(GOOGLE_FORM_POST_URL, {
            method: "POST",
            mode: "no-cors",
            headers: {
              "Content-Type": "application/form-data",
            },
            body: formData,
          }).catch(errorHandlerReport);
          signoutFirebase();
          dispatch(abortRequests());
          dispatch(clearRequestsCache());
          dispatch(resetRequests());
          dispatch(clearCurrentUser());
          clearAccessToken();
          if (canUseAppBridge()) {
            callHideBackButtonInApp();
          }

          window.dataLayer.push({ event: "delete_user" });
          await navigate("/settings/leave/complete/", { replace: true, state: { linkType } });
        })
        .catch(async (error) => {
          const message = await checkErrorResponse(error);
          errorHandlerReport(error);
          toastError(`退会処理に失敗しました。${message}`);
        });
    },
    [dispatch, linkType]
  );
};

export default useDeleteUser;
