import { getAccessToken } from "@mgdx-libs/firebase";

import { CalendarApi, Configuration } from "./clinic/clinic";
import { createFetchTimeout } from "./createFetchTimeout";
import requestHeaders from "./requestHeaders";

export const clinicCalendarApi = new CalendarApi(
  new Configuration({
    basePath: process.env.MGDX_API_CLINIC_URL,
    credentials: "include",
    accessToken: getAccessToken,
    headers: requestHeaders,
    fetchApi: createFetchTimeout(60000, "clinicCalendarApi"),
  })
);
