import { createFetchTimeout } from "@mgdx/api/lib/createFetchTimeout";
import { Configuration, LinkedPatientApi } from "@mgdx/api/lib/patient/patient";
import requestHeaders from "@mgdx/api/lib/requestHeaders";
import { getApiError } from "@mgdx-libs/error-handler";
import { forceRefreshAccessToken, getAccessToken } from "@mgdx-libs/firebase";
import { logger } from "@mgdx-libs/logger";
import { Dispatch } from "redux";

import { setCurrentUserWithDispatch } from "../../ducks/currentUser";
import { createCustomTokenUser } from "../../hooks/useInitializeLiff";

// memo: Cookieの利用のため、pathをyqbに変更
export const linkedPatientWithCookieApi = new LinkedPatientApi(
  new Configuration({
    basePath: process.env.MGDX_YQB_URL + "/api-patient",
    credentials: "include",
    accessToken: getAccessToken,
    headers: { ...requestHeaders, ...(process.env.MGDX_API_KEY && { "X-MGDX-API-KEY": process.env.MGDX_API_KEY }) },
    fetchApi: createFetchTimeout(60000, "linkedPatientWithCookieApi"),
  })
);

export const createNewPatientWithMccmOidcFirebaseUser = async (dispatch: Dispatch) => {
  const response = await linkedPatientWithCookieApi
    .postLinkedPatients({
      postLinkedPatientRequestBody: {},
    })
    .catch(async (errorOrResponse) => {
      throw await getApiError(errorOrResponse);
    });

  const { patient } = response;
  logger.debug("patient: %o", patient);

  if (!patient) {
    const error = new Error("patient is undefined");
    logger.error(error.message);
    throw error;
  }

  await forceRefreshAccessToken();
  setCurrentUserWithDispatch(dispatch, patient);

  return patient;
};

export const linkExistingPatientWithMccmOidcFirebaseUser = async (
  dispatch: Dispatch,
  { code, state }: { code?: string; state?: string }
) => {
  const response = await linkedPatientWithCookieApi
    .postLinkedPatients({
      postLinkedPatientRequestBody: { linkUserToken: code },
      state,
    })
    .catch(async (errorOrResponse) => {
      throw await getApiError(errorOrResponse);
    });

  const { patient, customToken } = response;
  logger.debug("patient: %o", patient);

  if (!patient || !customToken) {
    const error = new Error(`patient or customToken is undefined: patient=${patient}, customToken=${customToken}`);
    logger.error(error.message);
    throw error;
  }

  await createCustomTokenUser(customToken);
  setCurrentUserWithDispatch(dispatch, patient);

  return patient;
};
